@import url(https://fonts.googleapis.com/css2?family=Anton);
@import url(https://fonts.googleapis.com/css2?family=Raleway);
@import url(https://fonts.googleapis.com/css2?family=Lato);
@import url(https://fonts.googleapis.com/css2?family=Elsie);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




.marginMenu {
  flex-shrink: 0;
}
@media (min-width: 1071px) {
  .barmobile {
    display: none;
  }
}

@media (max-width: 1070px) {
  .bardesktop {
    display: none;
  }
}

@media (max-width: 991px) {
  .logoCol {
    order: 1;
  }
  .menuCol {
    order: 3;
  }
  .buttonCol {
    order: 2;
  }
}

@media (max-width: 817px) {
  .logoCol {
    order: 1;
  }
  .menuCol {
    order: 2;
  }
  .buttonCol {
    order: 3;
  }
  .dropItem {
    white-space: unset;
  }
}

.test {
  align-items: baseline;
}

.dropTitle {
  background-color: white !important;
  color: grey !important;
  border: none !important;
  font-size: 17px !important;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.dropTitle:hover {
  color: #47d1f3 !important;
}

.dropMenu {
  border: 1px solid #2d3e4f !important;
}

.dropItem {
  padding: 15px !important;
}

.dropdown-toggle::after {
  display: none !important;
}

#Language {
  width: 76px;
  z-index: 100;
}

#dropdown-basic {
  padding: 7px 0 !important;
  padding-left: unset;
  height: 100%;
  vertical-align: unset;
}

#dropdown-basic:focus {
  box-shadow: none;
}

.nav-link {
  padding: 7px 15px !important;
  text-align: end;
}

h2 {
  font-size: 50px;
  letter-spacing: 1.27px !important;
  font-weight: 700;
}

h3 {
  font-size: 34px;
  letter-spacing: 1.27px !important;
  font-weight: 700;
}

h6 {
  font-size: 20px;
  letter-spacing: 0.64px !important;
  font-weight: 600;
}

body {
  font-size: 16px;
  letter-spacing: 0.02px !important;
  color: #2d3e4f;
}

.title {
  text-align: center;
  font-family: "Elsie";
}

.label {
  color: #2d3e4f;
  font-weight: 600;
}

.testimonial-carousel .carousel-control-prev {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 50px;
  background-position: center;
}

.testimonial-carousel .carousel-control-next {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 50px;
  background-position: center;
}

.TestimonialsSlider {
  text-align: center;
}

.button {
  background-color: #47d1f3;
  color: white;
  border: none;
  font-size: 16px;
  font-family: "Raleway";
  padding: 8px 10px;
  border-radius: 5px;
  letter-spacing: 0.1px;
  font-weight: 600;
}

.carousel-caption {
  text-align: unset;
  z-index: unset;
}

/**********************************************************/
@media (min-width: 1050px) {
  .cardAnimation {
    -webkit-transition-duration: 0.2s;
            transition-duration: 0.2s;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
  }
  .cardAnimation:hover {
    -webkit-transform: scale(1.01) translateZ(0);
            transform: scale(1.01) translateZ(0);
    box-shadow: 0px 0px 10px #2d3e4e33;
  }
}
.specializies {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.28)), to(rgba(0, 0, 0, 0.28))),
    url(/static/media/cosmetic1.8d797a98.jpg);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.28), rgba(0, 0, 0, 0.28)),
    url(/static/media/cosmetic1.8d797a98.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 260px;
  cursor: pointer;
}
.specializies1 {
  background: -webkit-gradient(
      linear,
      left bottom, left top,
      from(rgba(255, 255, 255, 0.28)),
      to(rgba(255, 255, 255, 0.28))
    ),
    url(/static/media/orbital1.a2c5396a.jpg);
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.28),
      rgba(255, 255, 255, 0.28)
    ),
    url(/static/media/orbital1.a2c5396a.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 260px;
  cursor: pointer;
}
.specializies2 {
  background: -webkit-gradient(
      linear,
      left bottom, left top,
      from(rgba(255, 255, 255, 0.28)),
      to(rgba(255, 255, 255, 0.28))
    ),
    url(/static/media/eyelid1.14e68ef9.jpg);
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.28),
      rgba(255, 255, 255, 0.28)
    ),
    url(/static/media/eyelid1.14e68ef9.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 260px;
  cursor: pointer;
}
.pSpecializies {
  text-align: center;
  font-family: Lato;
  font-size: 22px;
  font-weight: 700;
  line-height: 33px;
  padding: 5rem;
  z-index: 10;
  color: black;
}
.carouselItem {
  width: 100%;
  padding: 35px 0px;
  background-image: url(/static/media/SliderBlue.4d8401e7.png);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: right;
  object-position: right top;
  align-items: center;
  display: flex;
  background-color: #48d1f3;
}

.procedures {
    background-image: url('https://r7z9r7i7.stackpathcdn.com/wp-content/uploads/2014/07/procedures-page-banner-1.png');

    background-repeat: no-repeat;
    background-color: #f4f4f4;
    background-size: auto 100%;
    background-position: bottom center;
    height: 152px;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
}

h1 {
    font-size: 50px;
}

.aProcedure {
    color: #8e97a0;
}
.aProcedure:hover {
    text-decoration: none;
}
.h1 {
    color: #47d1f3;
    text-align: center;
}
.paraProcedure {
    text-align: justify;
    margin-top: 15px;
}
ul {
    margin-top: 8px;
}
ul li {
    list-style-type: none;
}
.liAccordion {
    list-style: none;
    color: #8e97a0;
    font-weight: 700;
    font-size: 14px;
    font-family: Lato;
    padding: 5px 5px;
    margin-left: 5px;
    line-height: 14px;
}
.liAccordion a:hover {
    color: #47d1f3;
}

.btn-link {
    text-decoration: none;
    color: #2d3e4f;
    white-space: nowrap;
    font-weight: 700;
    font-size: 14px;
    font-family: Lato;
    line-height: 14px;
}
.btn-link:hover {
    text-decoration: none;
    color: #2d3e4f;
}
.btn-link:focus {
    box-shadow: none;
    text-decoration: none;
}
.fa-arrow-down {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: -webkit-transform 0.1s linear;
    transition: -webkit-transform 0.1s linear;
    transition: transform 0.1s linear;
    transition: transform 0.1s linear, -webkit-transform 0.1s linear;
}
.fa-arrow-down.open {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: -webkit-transform 0.1s linear;
    transition: -webkit-transform 0.1s linear;
    transition: transform 0.1s linear;
    transition: transform 0.1s linear, -webkit-transform 0.1s linear;
}

.button {
  background-color: #47d1f3;
  color: white;
  border: none;
  font-size: 16px;
  font-family: "Raleway";
  padding: 8px 10px;
  border-radius: 5px;
  letter-spacing: 0.1px;
  font-weight: 600;
}
.imgMedia {
  width: 26.625rem;
  height: 21.625rem;
}
.colMedia {
  margin: unset;
  padding: unset;
  flex-grow: 0;
}

.label {
  color: #2D3E4F;
  font-weight: 600;
}

.contact {
  margin-bottom: 3%;
}
.contact span {
  color: black;
  text-align: start;
}
.contact {
  color: white;
  margin-top: 30px;
  flex-shrink: 1;
}
.contact a {
  color: white;
  text-decoration: none;
  font-size: 13px;
}
.paraf {
  text-align: center;
  margin-top: 50px;
  padding-left: 40px;
}
.paraf a {
  margin-right: 10px;
  margin-left: 10px;
}
.contact a:hover {
  color: grey;
  text-decoration: none;
}
.logof {
  width: 220px;
  margin-top: 50px;
  padding-left: 0px;
  justify-content: center;
}
.foot {
  display: flex;
  background-color: #47d1f3;
  justify-content: space-around;
}
.imgFooter {
  padding: unset;
  flex-grow: 0;
}

@media (max-width: 839px) {
  .menuFooter {
    flex-direction: column !important;
  }
}
@media (max-width: 976px) {
  .colFooter {
    min-width: 100% !important;
  }
  .imgFooter {
    padding: unset;
    flex-grow: 1;
    min-width: 80px;
    max-width: 100px;
  }
  .imgFooter img {
    width: 100%;
  }
}

.stafImg {
  border-radius: 10px;
  width: 200px;
  height: 300px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4);
  object-fit: cover;
  object-position: 0 0;
}
@media (min-width: 717px) {
  .stafImg {
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
    -webkit-transition-duration: 0.45s;
            transition-duration: 0.45s;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4);
  }
  .stafImg:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    z-index: 10000;
    /* box-shadow: 5px 10px 5px #2d3e4f; */
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4);
  }
}
.pAboutNumber {
  color: #47d1f3;
  text-align: center;
  font-family: Lato;
  font-weight: 700;
  font-size: 54px;
  margin-bottom: 0px;
}
.pAboutOperation {
  text-align: center;
  font-weight: 400;
  font-size: 20px;
}

