h2 {
  font-size: 50px;
  letter-spacing: 1.27px !important;
  font-weight: 700;
}

h3 {
  font-size: 34px;
  letter-spacing: 1.27px !important;
  font-weight: 700;
}

h6 {
  font-size: 20px;
  letter-spacing: 0.64px !important;
  font-weight: 600;
}

body {
  font-size: 16px;
  letter-spacing: 0.02px !important;
  color: #2d3e4f;
}

.title {
  text-align: center;
  font-family: "Elsie";
}

.label {
  color: #2d3e4f;
  font-weight: 600;
}

.testimonial-carousel .carousel-control-prev {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 50px;
  background-position: center;
}

.testimonial-carousel .carousel-control-next {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 50px;
  background-position: center;
}

.TestimonialsSlider {
  text-align: center;
}

.button {
  background-color: #47d1f3;
  color: white;
  border: none;
  font-size: 16px;
  font-family: "Raleway";
  padding: 8px 10px;
  border-radius: 5px;
  letter-spacing: 0.1px;
  font-weight: 600;
}

.carousel-caption {
  text-align: unset;
  z-index: unset;
}

/**********************************************************/
@media (min-width: 1050px) {
  .cardAnimation {
    transition-duration: 0.2s;
    transition-timing-function: linear;
    transition-property: transform;
  }
  .cardAnimation:hover {
    transform: scale(1.01) translateZ(0);
    box-shadow: 0px 0px 10px #2d3e4e33;
  }
}
.specializies {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.28), rgba(0, 0, 0, 0.28)),
    url(../../assets/cosmetic1.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 260px;
  cursor: pointer;
}
.specializies1 {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.28),
      rgba(255, 255, 255, 0.28)
    ),
    url(../../assets/orbital1.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 260px;
  cursor: pointer;
}
.specializies2 {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.28),
      rgba(255, 255, 255, 0.28)
    ),
    url(../../assets/eyelid1.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 260px;
  cursor: pointer;
}
.pSpecializies {
  text-align: center;
  font-family: Lato;
  font-size: 22px;
  font-weight: 700;
  line-height: 33px;
  padding: 5rem;
  z-index: 10;
  color: black;
}
.carouselItem {
  width: 100%;
  padding: 35px 0px;
  background-image: url(../../assets/SliderBlue.png);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: right;
  object-position: right top;
  align-items: center;
  display: flex;
  background-color: #48d1f3;
}
