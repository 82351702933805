.button {
  background-color: #47d1f3;
  color: white;
  border: none;
  font-size: 16px;
  font-family: "Raleway";
  padding: 8px 10px;
  border-radius: 5px;
  letter-spacing: 0.1px;
  font-weight: 600;
}
.imgMedia {
  width: 26.625rem;
  height: 21.625rem;
}
.colMedia {
  margin: unset;
  padding: unset;
  flex-grow: 0;
}
