.label {
  color: #2D3E4F;
  font-weight: 600;
}

.contact {
  margin-bottom: 3%;
}
.contact span {
  color: black;
  text-align: start;
}