.stafImg {
  border-radius: 10px;
  width: 200px;
  height: 300px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4);
  object-fit: cover;
  object-position: 0 0;
}
@media (min-width: 717px) {
  .stafImg {
    transition-property: transform;
    transition-timing-function: linear;
    transition-duration: 0.45s;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4);
  }
  .stafImg:hover {
    transform: scale(1.05);
    z-index: 10000;
    /* box-shadow: 5px 10px 5px #2d3e4f; */
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4);
  }
}
.pAboutNumber {
  color: #47d1f3;
  text-align: center;
  font-family: Lato;
  font-weight: 700;
  font-size: 54px;
  margin-bottom: 0px;
}
.pAboutOperation {
  text-align: center;
  font-weight: 400;
  font-size: 20px;
}
