.marginMenu {
  flex-shrink: 0;
}
@media (min-width: 1071px) {
  .barmobile {
    display: none;
  }
}

@media (max-width: 1070px) {
  .bardesktop {
    display: none;
  }
}

@media (max-width: 991px) {
  .logoCol {
    order: 1;
  }
  .menuCol {
    order: 3;
  }
  .buttonCol {
    order: 2;
  }
}

@media (max-width: 817px) {
  .logoCol {
    order: 1;
  }
  .menuCol {
    order: 2;
  }
  .buttonCol {
    order: 3;
  }
  .dropItem {
    white-space: unset;
  }
}

.test {
  align-items: baseline;
}

.dropTitle {
  background-color: white !important;
  color: grey !important;
  border: none !important;
  font-size: 17px !important;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.dropTitle:hover {
  color: #47d1f3 !important;
}

.dropMenu {
  border: 1px solid #2d3e4f !important;
}

.dropItem {
  padding: 15px !important;
}

.dropdown-toggle::after {
  display: none !important;
}

#Language {
  width: 76px;
  z-index: 100;
}

#dropdown-basic {
  padding: 7px 0 !important;
  padding-left: unset;
  height: 100%;
  vertical-align: unset;
}

#dropdown-basic:focus {
  box-shadow: none;
}

.nav-link {
  padding: 7px 15px !important;
  text-align: end;
}
