.procedures {
    background-image: url('https://r7z9r7i7.stackpathcdn.com/wp-content/uploads/2014/07/procedures-page-banner-1.png');

    background-repeat: no-repeat;
    background-color: #f4f4f4;
    background-size: auto 100%;
    background-position: bottom center;
    height: 152px;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
}

h1 {
    font-size: 50px;
}

.aProcedure {
    color: #8e97a0;
}
.aProcedure:hover {
    text-decoration: none;
}
.h1 {
    color: #47d1f3;
    text-align: center;
}
.paraProcedure {
    text-align: justify;
    margin-top: 15px;
}
ul {
    margin-top: 8px;
}
ul li {
    list-style-type: none;
}
.liAccordion {
    list-style: none;
    color: #8e97a0;
    font-weight: 700;
    font-size: 14px;
    font-family: Lato;
    padding: 5px 5px;
    margin-left: 5px;
    line-height: 14px;
}
.liAccordion a:hover {
    color: #47d1f3;
}

.btn-link {
    text-decoration: none;
    color: #2d3e4f;
    white-space: nowrap;
    font-weight: 700;
    font-size: 14px;
    font-family: Lato;
    line-height: 14px;
}
.btn-link:hover {
    text-decoration: none;
    color: #2d3e4f;
}
.btn-link:focus {
    box-shadow: none;
    text-decoration: none;
}
.fa-arrow-down {
    transform: rotate(0deg);
    transition: transform 0.1s linear;
}
.fa-arrow-down.open {
    transform: rotate(90deg);
    transition: transform 0.1s linear;
}
