.contact {
  color: white;
  margin-top: 30px;
  flex-shrink: 1;
}
.contact a {
  color: white;
  text-decoration: none;
  font-size: 13px;
}
.paraf {
  text-align: center;
  margin-top: 50px;
  padding-left: 40px;
}
.paraf a {
  margin-right: 10px;
  margin-left: 10px;
}
.contact a:hover {
  color: grey;
  text-decoration: none;
}
.logof {
  width: 220px;
  margin-top: 50px;
  padding-left: 0px;
  justify-content: center;
}
.foot {
  display: flex;
  background-color: #47d1f3;
  justify-content: space-around;
}
.imgFooter {
  padding: unset;
  flex-grow: 0;
}

@media (max-width: 839px) {
  .menuFooter {
    flex-direction: column !important;
  }
}
@media (max-width: 976px) {
  .colFooter {
    min-width: 100% !important;
  }
  .imgFooter {
    padding: unset;
    flex-grow: 1;
    min-width: 80px;
    max-width: 100px;
  }
  .imgFooter img {
    width: 100%;
  }
}
